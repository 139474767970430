interface Props {
  x: number;
  currentSelfTax?: number;
  withNpd: boolean;
}

const Tax = (props: Props) => {
  const { currentSelfTax, x, withNpd } = props;

  if (withNpd) {
    return (
      <span className="self-end">
        <span className="Subtitle2 text-smena_text-secondary">С налогом: </span>
        {Math.round((x / Number(currentSelfTax)) * 100) / 100} ₽
      </span>
    );
  } else {
    return (
      <span className="self-end">
        <span className="Subtitle2 text-smena_text-secondary">Без налога</span>
      </span>
    );
  }
};

export default Tax;
